import Footer from "../../includes/Footer/Footer";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./newHunt.css";
import React, { useEffect, useState } from "react";
import { API } from "../../../http-common";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { TO_SEARCH } from "../../../constants";


let OptionsList = require("./OptionsList.json");

const NewHunt = (req) => {
  const isEdit = (typeof req.match.params.id !== 'undefined')

  const [show, setShow] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  
  const handleCloseSearch = () => setSearchModal(false)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let history = useHistory();
  

  //Variables de formulario
  const [profileId, setProfileId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [name, setName] = useState("");
  const [linkedinProfile, setLinkedinProfile] = useState("");
  const [business, setBusiness] = useState("");
  const [business_id, setBusiness_id] = useState("");
  const [role, setRole] = useState("");
  const [country, setCountry] = useState("");
  const [notes, setNotes] = useState("");
  const [firstContact, setFirstContact] = useState("");
  const [lastContact, setLastContact] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [searchId, setSearchId] = useState(req.match.params.id ?? "");
  const [hunt, setHun] = useState({})
  const [httpStatus, setHttpStatus] = useState("");
  const [isSentData, setIsSentData] = useState(false);
  const [message, setMessage] = useState("Cargando...");


  // objeto Form

  const formInitial = [
    {
      search_id: searchId,
      name: name,
      linkedin_profile: linkedinProfile,
      profile_id: profileId,
      business: business,
      business_id: business_id,
      role: role,
      country: country,
      status_id: statusId,
      notes: notes,
      first_contact: firstContact,
      last_contact: lastContact,
      email: email,
      phone: phone
    },
  ];


  const [formData, setFormData] = useState(formInitial);

  //Variables de validacion de error
  const [profileIdError, setProfileIdError] = useState(false);
  const [statusIdError, setStatusIdError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [linkedinProfileError, setLinkedinProfileError] = useState("");
  const [businessError, setBusinessError] = useState("");
  const [businessOtherError, setBusinessOtherError] = useState(false);
  const [businessOtherWarning, setBusinessOtherWarning] = useState("");
  const [roleError, setRoleError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [notesError, setNotesError] = useState("");
  const [firstContactError, setFirstContactError] = useState("");
  const [lastContactError, setLastContactError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [searchIdError, setSearchIdError] = useState("");

  //Variables de mensaje de error
  const [profileIdWarning, setProfileIdWarning] = useState("");
  const [statusIdWarning, setStatusIdWarning] = useState("");
  const [nameWarning, setNameWarning] = useState("");
  const [linkedinProfileWarning, setLinkedinProfileWarning] = useState("");
  const [businessWarning, setBusinessWarning] = useState("");
  const [roleWarning, setRoleWarning] = useState("");
  const [countryWarning, setCountryWarning] = useState("");
  const [notesWarning, setNotesWarning] = useState("");
  const [firstContactWarning, setFirstContactWarning] = useState("");
  const [lastContactWarning, setLastContactWarning] = useState("");
  const [emailWarning, setEmailWarning] = useState("");
  const [phoneWarning, setPhoneWarning] = useState("");
  const [searchIdWarning, setSearchIdWarning] = useState("");
  const [listCompanies, setListCompanies] = useState([])
  const regExDate =
    /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/;
  const regExName = /^[a-zA-Z]+[a-zA-Z]+$/;
  const regExLetters = /^[a-zA-ZÀ-ú ]+$/;
  const regExEmail = /\S+@\S+\.\S+/;
  const regLinkedingUrl = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gm;

  const [profiles, setProfiles] = useState([]);
  const [status, setStatus] = useState([]);
  const [positions, setPositions] = useState([]);

  function getProfiles() {
    API.get("/hunt/profiles").then(function (response) {
      if (response.data.error === 0) {
        setProfiles(response.data.data);
      } else {
        //todo
      }
    })
    .catch(err => {
      console.log(err)
    });
  }

  function getStatus() {
    API.get("/hunt/status").then(function (response) {
      if (response.data.error === 0) {
        setStatus(response.data.data);
      } else {
        //todo
      }
    });
  }

  function getActivePositions() {
    API.get("/positions/active").then(function (response) {
      if (response.data.error === 0) {
        setPositions(response.data.data)
      } else {
        //todo
      }
    });
  }

  function getPreCandidate() {
    if(isEdit) {
      API.get("/hunt/"+req.match.params.id).then(function (response) {
        if (response.data.error === 0) {
          const data = response.data.data;
          setHun(data)
          setProfileId(data.profile_id);
          setStatusId(data.status_id);
          setName(data.name);
          setLinkedinProfile(data.linkedin_profile);
          setBusiness(data.business);
          setBusiness_id(data.business_id.toString());
          setRole(data.role);
          setCountry(data.country);
          setNotes(data.notes);
          setFirstContact(data.first_contact);
          setLastContact(data.last_contact);
          setEmail(data.email);
          setPhone(data.phone);
          setSearchId(data.search_id);
          
          setFormData([{
            ...formData,
            search_id: data.search_id,
            profile_id: data.profile_id,
            status_id: data.status_id,
            name: data.name,
            linkedin_profile: data.linkedin_profile,
            business: data.business,
            business_id: data.business_id,
            role: data.role,
            country: data.country,
            notes: data.notes,
            first_contact: data.first_contact,
            last_contact: data.last_contact,
            email: data.email,
            phone: data.phone,
          }])

        } else {
          history.push('/hunt')
        }
      }).catch(() => {
        history.push('/hunt')
      });
    }
    
  }

  const checkvalue = (nameInput, value) => {
    if ((value === "" || /^[a-zA-ZÀ-ú ]+$/.test(value)) && nameInput === "name") {
      setName(value);
      updateFormData(nameInput, value);
    } else if (
      nameInput === "linkedin_profile"
    ) {
      setLinkedinProfile(value);
      updateFormData(nameInput, value);
    } else if (
      (value === "" || /^[0-9\b]+$/.test(value)) &&
      nameInput === "profile_id"
    ) {
      setProfileId(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "business_id") {
      setBusiness_id(value);
      updateFormData(nameInput, value);
    }
    else if (nameInput === "business") {
      setBusiness(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "role") {
      setRole(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "country") {
      setCountry(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "status_id") {
      setStatusId(value);
      updateFormData(nameInput, value);
    } else if (
      nameInput === "notes"
    ) {
      setNotes(value);
      updateFormData(nameInput, value);
    } else if ( nameInput === "first_contact") {
      setFirstContact(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "last_contact") {
      setLastContact(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "email") {
      setEmail(value);
      updateFormData(nameInput, value);
    } else if (nameInput === "phone") {
      setPhone(value);
      updateFormData(nameInput, value);
    }else if  (nameInput === 'search_id') {
      setSearchId(value);
      updateFormData(nameInput, value);
    }
  };


  const updateFormData = (nameInput, value) => {
    const newForm = formData.map((item) => {
      const updatedItem = { ...item, [nameInput]: value };
      return updatedItem;
    });
    setFormData(newForm);
  };

  const checkEmpty = () => {
    let flag = 0;
    if (name.trim().length === 0) {
      flag++;
      setNameError(true);
      setNameWarning("El campo no puede estar vacío");
    } else if (!regExName.test(name.replaceAll(' ', ''))) {
      console.log('error');
      flag++;
      setNameError(true);
      setNameWarning("Ingrese solo letras");
    } else {
      setNameError(false);
      setNameWarning("");
    }

    if (linkedinProfile.trim().length === 0) {
      flag++;
      setLinkedinProfileError(true);
      setLinkedinProfileWarning("El campo no puede estar vacío");
    } 
    else if (!regLinkedingUrl.test(linkedinProfile)) {
      flag++;
      setLinkedinProfileError(true);
      setLinkedinProfileWarning("Url de linkedin inválida");
    }
    else {
      setLinkedinProfileError(false);
      setLinkedinProfileWarning("");
    }

    if (profileId.length === 0) {
      flag++;
      setProfileIdError(true);
      setProfileIdWarning("El campo no puede estar vacío");
    } else {
      setProfileIdError(false);
      setProfileIdWarning("");
    }

    if (business_id.length === 0) { 
      flag++;
      setBusinessError(true);
      setBusinessWarning("El campo no puede estar vacío");
    } else {
      setBusinessError(false);
      setBusinessWarning("");
    }
    if(business_id === "1" && business.length === 0){
      flag++;
      setBusinessOtherError(true);
      setBusinessOtherWarning("El campo no puede estar vacío");
    } else {
      setBusinessOtherError(false);
      setBusinessOtherWarning("");
    }

    if (role.length === 0) {
      flag++;
      setRoleError(true);
      setRoleWarning("El campo no puede estar vacío");
    } else {
      setRoleError(false);
      setRoleWarning("");
    }

    if (email.trim().length > 0 && !regExEmail.test(email)) {
      flag++;
      setEmailError(true);
      setEmailWarning("Ingrese un correo valido");
    } else {
      setEmailError(false);
      setEmailWarning("");
    }

    if (country.length === 0) {
      flag++;
      setCountryError(true);
      setCountryWarning("El campo no puede estar vacío");
    } else {
      setCountryError(false);
      setCountryWarning("");
    }

    
    if (statusId.length === 0) {
      flag++;
      setStatusIdError(true);
      setStatusIdWarning("El campo no puede estar vacío");
    } else {
      setStatusIdError(false);
      setStatusIdWarning("");
    }

    if (firstContact.length === 0) {
      flag++;
      setFirstContactError(true);
      setFirstContactWarning("El campo no puede estar vacío");
    } else {
      setFirstContactError(false);
      setFirstContactWarning("");
    }

    if (lastContact.length === 0) {
      flag++;
      setLastContactError(true);
      setLastContactWarning("El campo no puede estar vacío");
    } else {
      setLastContactError(false);
      setLastContactWarning("");
    }

    
    if (flag === 0) {
      setIsSentData(true);
    }
    return flag;
  };
  
  const sendRequest = async (url, data, msg) => {
    API.post(url, data)
    .then(function (response) {
      if (response.data.error === 0) {
        setMessage(msg);
        setHttpStatus(response.status);
        setIsSentData(false);
        handleShow();
        setStatusId(parseInt(response.data.status_id))
        setHun({
          ...hunt,
          status_id: response.data.status_id
        })

      }else{
        setMessage(response.data.message);
        setHttpStatus(500);
        setIsSentData(false);
        handleShow();
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 500) {
          msg = (
              <>
                Hubo un problema al registrar los datos, por favor intenta
                nuevamente
              </>
          );
          setMessage(msg);
          setHttpStatus(error.response.status);
          setIsSentData(false);
          handleShow();
        } else if (error.response.status === 400) {
          msg = <>{error.response.data.message}</>;
          setMessage(msg);
          setHttpStatus(error.response.status);
          setIsSentData(false);
          handleShow();
        }
      } else {
        msg = (
            <>
              Hubo un problema al conectar con el servidor, verifica tu conexión
              a Internet e intenta nuevamente
            </>
        );
        setMessage(msg);
        setIsSentData(false);
        handleShow();
      }
    });
  }

  const requestMode = () => {
    if(isEdit) {
      return {
        url: "/hunt/edit/"+req.match.params.id,
        msg:  <>Los datos del pre-candidato fueron actualizados de manera exitosa</>
      }
    }

    return {
      url: "/hunt/create",
      msg:  <>Los datos del pre-candidato fueron creados de manera exitosa</>
    }
  }

  const sendData = () => {
    
    const requestData = requestMode()
    if(parseInt(formData[0].status_id) === TO_SEARCH && parseInt(hunt.status_id) !== TO_SEARCH) {
      setSearchModal(true)
      setIsSentData(false)
    }else{
      sendRequest(requestData.url, formData[0], requestData.msg)
    }
  };

  const sendDataWithSearch = () => {
    const requestData = requestMode()
    if ( searchId.toString().trim().length === 0 ) {
      setSearchIdError(true)
      setSearchIdWarning('Debe seleccionar uns búsqueda')
      return 
    }
    setSearchIdError(false)
    setSearchIdWarning('')
    handleCloseSearch()
    sendRequest(requestData.url, formData[0], requestData.msg)
  }



  const handleSubmit = (e) => {
    e.preventDefault();
    checkEmpty();
  };

  const getListCompanies = () => {
    try {
      API.get(`/companies`).then(function (response) {
        if (response.data.error === 0) {
          let list = response.data.data
          setListCompanies(list)
        }
      }  );
    }
    catch {
      setListCompanies([])
     throw new Error("Error al cargar la lista de empresas")
    }
  }
  useEffect(() => {
    getProfiles();
    getStatus();
    getPreCandidate();
    getActivePositions();
    getListCompanies();
  }, []);

  useEffect(() => {
    if (isSentData) {
      sendData();
    } else {
      setIsSentData(false);
    }
  }, [isSentData]);

  return (
    <>
      <Header />
      <Menu />
      <div className="content-formulario ">
        <div className="formulario newapplicant">
          <form method="POST" onSubmit={handleSubmit}>

            <div className="content-datos">
              <div className="content-linea">
                <div className="input-error">
                  <span>Nombre <span class="text-danger">*</span></span>
                  <input
                    name="name"
                    value={name}
                    type="text"
                    id="name"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Nombre"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      nameError ? "" : "d-none"
                    }`}
                  >
                    {nameWarning}
                  </div>
                </div>
                <div className="input-error">
                  <span>Perfil de linkedin <span class="text-danger">*</span></span>
                  <input
                    name="linkedin_profile"
                    value={linkedinProfile}
                    type="text"
                    id="linkedin_profile"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Perfil de linkedin"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      linkedinProfileError ? "" : "d-none"
                    }`}
                  >
                    {linkedinProfileWarning}
                  </div>
                </div>
              </div>
              <div className="content-linea">
                <div className="input-error">
                  <span>Perfil de contacto <span class="text-danger">*</span></span>
                  <select
                    name="profile_id"
                    style={{ marginTop: "5px" }}
                    value={profileId}
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                  >
                    <option value>Perfil contacto</option>
                    {profiles.map((p) => (
                      <option key={p.id} value={p.id}>{p.name}</option>
                    ))}
                  </select>

                  <div
                    className={`alert alert-danger mt-3 ${
                      profileIdError ? "" : "d-none"
                    }`}
                  >
                    {profileIdWarning}
                  </div>
                </div>

                <div className="input-error">
                  <span>Empresa <span class="text-danger">*</span></span>
                  <select
                    name="business_id"
                    style={{ marginTop: "5px" }}
                    value={business_id}
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                  >
                  
                    <option value={business ? business : ""}>
                      {business ? business : "Seleccionar"}
                    </option>
                    {listCompanies.map((item, i) => <option key={i} value={item.id}>{item.name}</option>)}
                    </select>
                    {
                        business_id === "1" && (
                          <input
                        name="business"
                        value={business}
                        type="text"
                        id="business"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Nombre de la empresa"
                      />
                        )
                      }
                      <div
                        className={`alert alert-danger mt-3 ${
                          businessError == 1 ? "" : "d-none"
                        }`}
                      >
                        {businessWarning}
                      </div>
                      <div
                        className={`alert alert-danger mt-3 ${
                          businessOtherError == 1 ? "" : "d-none"
                        }`}
                      >
                        {businessOtherWarning}
                      
                      </div>
                </div>

              </div>
              <div className="content-linea">

                <div className="input-error">
                  <span>Cargo/Rol <span class="text-danger">*</span></span>
                  <input
                    name="role"
                    value={role}
                    type="text"
                    id="role"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Cargo/Rol"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      roleError ? "" : "d-none"
                    }`}
                  >
                    {roleWarning}
                  </div>
                </div>

                <div className="input-error">
                  <span>País <span class="text-danger">*</span></span>
                  <select
                    name="country"
                    style={{ marginTop: "5px" }}
                    value={country}
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                  >
                    <option value>-Seleccionar-</option>
                    {OptionsList.countries.map(c => (
                      <option value={c.code} key={c.code}>{c.name}</option>
                    ))}
                  </select>

                  <div
                    className={`alert alert-danger mt-3 ${
                      countryError ? "" : "d-none"
                    }`}
                  >
                    {countryWarning}
                  </div>
                </div>

                
                    
              </div>

              <div className="content-linea">
                  <div className="input-error">
                    <span>Estado <span class="text-danger">*</span></span>
                    <select
                      style={{ marginTop: "5px" }}
                      name="status_id"
                      value={statusId}
                      onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    >
                      <option value>Estado</option>
                      {status.map((p) => (
                        <option key={p.id} value={p.id}>{p.name}</option>
                      ))}
                    </select>

                    <div
                      className={`alert alert-danger mt-3 ${
                        statusIdError ? "" : "d-none"
                      }`}
                    >
                      {statusIdWarning}
                    </div>
                  </div>
                

                <div className="input-error">
                  <span>Primera fecha de contacto <span class="text-danger">*</span></span>
                  <input
                    name="first_contact"
                    value={firstContact}
                    type="date"
                    id="first_contact"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Fecha primer contacto"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      firstContactError ? "" : "d-none"
                    }`}
                  >
                    {firstContactWarning}
                  </div>
                </div>
                
                
              
              </div>

              <div className="content-linea">
                
                <div className="input-error">
                  <span>Segunda fecha de contacto <span class="text-danger">*</span></span>
                  <input
                    name="last_contact"
                    value={lastContact}
                    type="date"
                    id="last_contact"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Fecha último contacto"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      lastContactError ? "" : "d-none"
                    }`}
                  >
                    {lastContactWarning}
                  </div>
                </div>

                <div className="input-error">
                  <span>Correo electrónico {parseInt(statusId) === TO_SEARCH && <span class="text-danger">*</span>} </span>
                  <input
                    name="email"
                    value={email}
                    type="email"
                    id="email"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Correo electrónico"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      emailError ? "" : "d-none"
                    }`}
                  >
                    {emailWarning}
                  </div>
                </div>


              </div>
              <div className="content-linea">

                <div className="input-error">
                  <span>Teléfono</span>
                  <input
                    name="phone"
                    value={phone}
                    type="text"
                    id="phone"
                    onChange={(e) => checkvalue(e.target.name, e.target.value)}
                    placeholder="Teléfono de contacto"
                  />
                  <div
                    className={`alert alert-danger mt-3 ${
                      phoneError ? "" : "d-none"
                    }`}
                  >
                    {phoneWarning}
                  </div>
                </div>

                
                
              </div>

              <div className="content-datos" style={{ padding: 0, border: 0 }}>
                <span class="item mb-3">Notas</span>
                <div className="input-error">
                      <textarea
                        name="notes"
                        id="notes"
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                        placeholder="Notas"
                        rows={10}
                        value={notes}
                        style={{width: '100%'}}
                      ></textarea>
                      <div
                        className={`alert alert-danger mt-3 ${
                          notesError ? "" : "d-none"
                        }`}
                      >
                        {notesWarning}
                      </div>
                    </div>
              </div>
            </div>
            <div className="botones">
              <span>* Todos los campos son obligatorios</span>

              <button
                type="submit"
                disabled={isSentData ? true : false}
                className="crear"
              >
                GUARDAR PRECANDIDATO
              </button>
            </div>
          </form>
        </div>
      </div>

      <Modal
        show={show}
        backdrop={"static"}
        className="modal_candidato"
        onHide={handleClose}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          {httpStatus === 200 ? (
            <Link to="/hunt">
              <Button variant="primary">Ir a los Pre-candidatos</Button>
            </Link>
          ) : (
            <Button onClick={handleClose}>Cerrar</Button>
          )}
        </Modal.Footer>
      </Modal>

      <Modal
        show={searchModal}
        backdrop={"static"}
        className="modal_candidato"
        onHide={handleCloseSearch}
      >
        <Modal.Header closeButton></Modal.Header>
        {parseInt(statusId) === TO_SEARCH && email.trim().length === 0 ? (
          <>
            <Modal.Body>
              <p>No puedes elegir este status sin completar el campo de email. Este dato es necesario para poder completar la acción de postulación del candidato a una búsqueda</p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={handleCloseSearch}>Cerrar</Button>
            </Modal.Footer>
          </>
        ): (
          <>
            <Modal.Body>
              <div className="input-error content-datos">
                <span class="item mb-2">Seleccionar una búsqueda: </span>
                <select value={searchId} name="search_id" onChange={(e) => checkvalue(e.target.name, e.target.value)} id="search_id">
                    <option value="">-Seleccionar-</option>
                    {positions.map((item, i) => <option key={i} value={item.id_search}>{item.id_search} - {item.position_name}</option>)}
                </select>
                <div
                  className={`alert alert-danger mt-3 ${
                    searchIdError ? "" : "d-none"
                  }`}
                >
                  {searchIdWarning}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => sendDataWithSearch()} variant="primary">Guardar pre-candidato</Button>
            </Modal.Footer>
          </>
        )}
        
      </Modal> 
      <Footer />
      <Footer />
    </>
  );
};

export default NewHunt;
