import React, { useEffect, useRef, useState, useContext } from "react";
import curriculum from "../../../../../../images/menu/curriculum.png";
import { UserContext } from "../../../../../../context/userContext";
import { DataContext } from "../../../../../../context/dataContext";
import { API } from "../../../../../../http-common";
import $ from "jquery";
import moment from "moment";
/*Edit modules imports*/
import EditModule from "../EditModules";
import EditFeedback from "../EditFeedback";
import LoadingList from "../../../../Loading/LoadingList";
import EditDate from "../EditDate";
const Accordion6 = ({
  available,
  setAvailable,
  idSearch,
  module,
  applicant,
  disableEdit,
  onDataChange = null
}) => {
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [submit, setSubmit] = useState(false);
  const { userData, setUserData } = useContext(UserContext);
  const [nameFile, setNameFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [feedback, setFeedback] = useState(module.data.feedback);
  const [moduleStatus, setModuleStatus] = useState(module.status);
  const [feedbackError, setFeedbackError] = useState(false);
  const [selectedFileError, setSelectedFileError] = useState(false);
  const [acceptedDate, setAcceptedDate] = useState(module.admission_date ? module.admission_date : moment(new Date()).format("YYYY-MM-DD"));
  const [errorDate, setErrorDate] = useState(false);
  const [addmissionDate, setAdmissionDate] = useState(module.admission_date);
  /*EditModal states & function*/
  const [showModal, setShowModal] = useState(false);
  const [moduleWritable, setModuleWritable] = useState(module.writable);
  const [dataModule, setDataModule] = useState(module);
  const [feedbackDate, setFeedbackDate] = useState(
      dataModule.data.feedback_date !== '' ?
      moment(dataModule.data.feedback_date).format("YYYY-MM-DD") :
      moment(new Date()).format("YYYY-MM-DD")
  );
 const [loading, setLoading] = useState(false);
 const [isLoading, setIsLoading] = useState(false)
 const [showModalDate, setshowModalDate] = useState(false); //Nuevo estado para editar la fecha de entrevista
 const [date, setDate] = useState(
  module.data.date !== ""
    ? moment(module.data.date, "DD/MM/YYYY").format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD")
);
  const editionMode = () => {
    setModuleWritable(1);
    setShowModal(false);
  };

  const toggleClass = () => {
    let modal = document.getElementsByClassName("modal_edit_applicant");
    if(modal.length > 0){
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }
  }

  const isModal = document.getElementsByClassName("modal_edit_applicant");

  const file = useRef(null);

  const checkvalue = (nameInput, value) => {
    setLoading(true)
    setTimeout(() => {
      if (nameInput === "selectedFile") {
        setSelectedFile(value);
        setNameFile(value.name);
      }
      setLoading(false)
    }, 500);
  };

  /*const getCandidate = async () => {
    await API.get("/applications/timeline/" + applicant.applicant_id).then(
      function (response) {
        if (response.data.error === 0) {
          setDataModule(response.data.data.modules[5]);
          setFeedback(response.data.data.modules[5].data.feedback);
          setModuleStatus(response.data.data.modules[5].status);
        } else {
          //todo
        }
      }
    );
  };*/

  useEffect(async () => {
    if (submit && !feedbackError && !selectedFileError) {
      sendData();
    }
    // getCandidate();
    setSubmit(false);
  }, [submit]);

  useEffect(() => {
    fixFeedbackDate();
  }, []);

  const handleClick = (e) => {
    file.current.click();
  };

  // const onFileChange = file => {

  //   // Update the state
  //   setSelectedFile(file);

  // };

  // const form = new FormData();

  const sendData = () => {
    // const fecha =  (module.data.date!=='') ? module.data.date : moment(new Date()).format("YYYY-MM-DD")
    setLoading(true)
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 6,
      employees_id: userData.id,
      feedback_date: feedbackDate,
      feedback: feedback,
      accepted: null,
      link_note: selectedFile,
      status: moduleStatus,
    };

    const formData = new FormData();

    if (selectedFile !== "") {
      const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."));
      const fileName =
        `EXAMEN_MEDICO_CANDIDATO_${applicant.dni}_${applicant.username}${ext}`
          .split(" ")
          .join("_")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      formData.append("selectedFile", dataForm.link_note, fileName);

      dataForm.link_note = fileName;
    }
    // formData.append('selectedFile', selectedFile, selectedFile.name)
    const module_id = dataModule.id ?? 0;
      try{
        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
          // Petición para el envío de documento
          API.post("/applications/file", formData)
            .then((response) => {})
            .catch((error) => {});
          setModuleWritable(0);
          setDataModule({
            ...dataModule,
            id: response.data.id
          })
          if(null !== onDataChange) onDataChange()
          toggleClass()
          setLoading(false)
        })
      }
      catch(error){
        console.log(error)
        setLoading(false)
      }
  };

  const handleInterview6 = (e) => {
    let status = e.currentTarget.id;
    switch (status) {
      case "ok_avanza":
        setModuleStatus(1);
        break;
      case "ok_no_avanza":
        setModuleStatus(2);
        break;
      case "ok_guarda":
        setModuleStatus(3);
        break;
      default:
        setModuleStatus(0);
    }
    setSubmit(true);
    checkInputs(status);
  };

  const checkInputs = (status) => {
    if (feedback.length === 0 && status !== 'ok_dicidir') {
      setFeedbackError(true);
    } else {
      setFeedbackError(false);
    }

    /*if (module.completed === 0 && selectedFile.length === 0) {
      setSelectedFileError(true);
    } else {
      setSelectedFileError(false);
    }*/
  };


  const handleChange = e => {
    const { value } = e.target;
    setAcceptedDate(value)
  }

  const sendAcceptedDate = () => {
    const dataForm = {
        search_id: idSearch,
        candidate_id: applicant.candidate_id,
        applicant_id: applicant.applicant_id,
        module_id: 6,
        employees_id: userData.id,
        feedback_date: feedbackDate,
        feedback: feedback,
        accepted: null,
        link_note: null,
        admission_date: acceptedDate,
        status: 3
    }
    setLoading(true)
    setErrorDate(false)
    if(!acceptedDate){
        setErrorDate(true)
    }else{
      try {
        const module_id = dataModule.id ?? 0;
        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
            setAdmissionDate(acceptedDate)
            setDataModule({
              ...dataModule,
              id: response.data.id
            })
            if (null !== onDataChange) onDataChange()
            toggleClass()
            setLoading(false)
        })
      }
        catch(e){
          console.log(e)
          setIsLoading(false)
        }
    }
  }
  const handdleDateChange = (e) => {
    const {value} = e.target;
    setFeedbackDate(value)
  }

  const fixFeedbackDate= () =>{
    if(module.status === 4){
      setDate(moment().format("YYYY-MM-DD"))
    }
  }
  
  const handleCloseDate = () => setshowModalDate(false); //Nueva funcion para editar la fecha de entrevista
  
  const editionModeDate = () => { //Nueva funcion para editar la fecha de entrevista
    
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 6,
      employees_id: userData.id,
      feedback_date: date,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: acceptedDate,
      status: 4,
    }
  
    setErrorDate(false)
    if(!acceptedDate){
      setErrorDate(true)
    }else{
      const module_id = dataModule.id ?? 0;
  
        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
          setAdmissionDate(acceptedDate)
          setDataModule({
            ...dataModule,
            id: response.data.id
          })
            if(null !== onDataChange) onDataChange()
          toggleClass()
  
        })
        .catch()
    }
    setshowModalDate(false);
  
  }
  return (
    <>
      <div className="card">
        <div className="card-header" id="medica-heading">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#medica"
              aria-expanded="false"
              aria-controls="medica"
            >
              Exámen Médico
            </button>
            <span
              className="mas"
              data-toggle="collapse"
              data-target="#medica"
              aria-expanded="false"
              aria-controls="medica"
            >
              +
            </span>
          </h5>
        </div>
        {/* <div id="psicotecnica" className={module.writable === 1 ? "collapse show" : "collapse disabled-accordion"} aria-labelledby="psicotecnica-heading"> */}
        <div
          id="medica"
          className={"collapse show"}
          aria-labelledby="medica-heading"
        >
          {/* <div id="psicotecnica" className={1 === 1 ? "collapse" : "collapse disabled-accordion"} aria-labelledby="psicotecnica-heading"> */}
          <div
            className={`card-body ${
              (disableEdit || moduleWritable === 0) && "disabled"
            }`}
          >
            {(addmissionDate !== null || module.completed === 1) && (
              <>
              {null !== addmissionDate ? <p>Agendado para el día {moment(addmissionDate).format('DD/MM/YYYY')}
                {moduleWritable === 1 && dataModule.editable == 'all' && (
                  <EditDate
                    editionModeDate={editionModeDate}
                    showModalDate={showModalDate}
                    handleCloseDate={handleCloseDate}
                    setshowModalDate={setshowModalDate}
                    handleChange={handleChange}
                    acceptedDate={acceptedDate}
                    errorDate={errorDate}
                    date={date}
                  >
                  </EditDate>
                )}
              </p> : <p>Sin fecha de examen</p>}
                {/* <div className={`card-body`} > */}
                <div className="barra-superior">
                  <div
                    className={
                      disableEdit ||
                      (moduleWritable === 0 && dataModule.completed === 1)
                        ? "left disabled-accordion"
                        : "left"
                    }
                  >

                    {/* <div className="left"> */}
                    {moduleWritable === 1 ? (
                      <div className="item">
                        <span>Fecha</span>
                        <input style={{margin: '15px 0'}} type="date" onChange={handdleDateChange} value={feedbackDate} />
                      </div>
                    ) : (
                      <div className="item">
                        <span>Fecha</span>
                        <label>{moment(feedbackDate).format("DD/MM/YYYY")}</label>
                      </div>
                    )}


                  </div>
                  <div className="right">
                    {disableEdit ||
                    (moduleWritable === 0 && dataModule.completed === 1) ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          dataModule.data.link.length > 0
                            ? dataModule.data.link
                            : "#"
                        }
                      >
                        <div className="curriculum notas">
                          <img src={curriculum} alt="CV" />
                          VER NOTAS
                        </div>
                      </a>
                    ) :
                    (
                      <>
                        
                        <input
                          accept=".pdf,.docx,.doc"
                          className="d-none"
                          ref={file}
                          name="selectedFile"
                          type="file"
                          onChange={(e) =>
                            checkvalue(e.target.name, e.target.files[0])
                          }
                        />
                         {
                          loading ? (
                            <>
                            <div
                          onClick={(e) => handleClick()}
                          className="curriculum notas"
                        >
                          <LoadingList size={15} padding="0" color="#fff"/>
                        </div>
                        <div className="mensajes">
                          <span
                            className={selectedFileError ? "span-error" : "d-none"}
                          >
                            Campo Vacio
                          </span>
                        </div>
                            </>
                          ):(
                            <>
                            <div
                          onClick={(e) => handleClick()}
                          className="curriculum notas"
                        >
                          <img src={curriculum} alt="CV" />
                          ADJUNTAR NOTAS
                        </div>
                        <div className="mensajes">
                          <span>{nameFile}</span>
                          <span
                            className={selectedFileError ? "span-error" : "d-none"}
                          >
                            Campo Vacio
                          </span>
                        </div> 
                         </> )}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="content-feedback">
                  <span>Feedback</span>
                  <textarea
                    id="feedback-medica"
                    cols="30"
                    rows="10"
                    placeholder=""
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    readOnly={moduleWritable === 0 ? true : false}
                    disabled={false}
                  ></textarea>
                  <span className={feedbackError ? "span-error" : "d-none"}>
                    Campo Vacio
                  </span>
                </div>
                {moduleWritable === 1 && (
                  <div className="botones-vista">
                    {/*<button id="aprobar" onClick={() => $('#avanza-busqueda-5').modal('show')} >GUARDAR NOTAS</button>*/}
                    <button
                      id="aprobar-6"
                      className="aprobar"
                      onClick={() => $("#avanza-busqueda-6").modal("show")}
                      data-toggle="modal"
                      data-target="#avanza-busqueda-6"
                      disabled={isLoading ? true : false}
                    >
                      AVANZA
                    </button>
                    <button
                      id="negar-6"
                      className="negar"
                      onClick={() => $("#no-avanza-busqueda-6").modal("show")}
                      data-toggle="modal"
                      data-target="#no-avanza-busqueda-6"
                      disabled={isLoading ? true : false}
                    >
                      NO AVANZA
                    </button>
                    <button
                      id="decidir-6"
                      className="decidir"
                      onClick={() => $("#decidir-busqueda-6").modal("show")}
                      data-toggle="modal"
                      data-target="#decidir-busqueda-6"
                      disabled={isLoading ? true : false}
                    >
                      DECIDIR MÁS TARDE
                    </button>
                  </div>
                )}
                {moduleWritable === 0 && (
                  <div className="estado">
                    {(() => {
                      switch (dataModule.status) {
                        case 0:
                          return <span className="pausado">PENDIENTE</span>;
                          break;
                        case 1:
                          //return (<span className="aprobado">AVANZA</span>);
                          return (
                            <span className="aprobado">ENTREVISTA REALIZADA</span>
                          );
                          break;
                        case 2:
                          return <span className="negado">NO AVANZA</span>;
                          break;
                        case 3:
                          return (
                            <span className="pausado">
                              GUARDADO PARA OTRA BÚSQUEDA
                            </span>
                          );
                          break;
                        default:
                          return <span className="pausado">PENDIENTE</span>;
                      }
                    })()}
                  </div>
                )}

                {dataModule.completed === 1 &&
                  dataModule.editable === "feedback" && (
                    <EditFeedback
                      setModuleWritable={setModuleWritable}
                      sendData={sendData}
                      feedbackError={feedbackError}
                    ></EditFeedback>
                  )}
                {dataModule.completed === 1 && dataModule.editable === "all" && (
                  <EditModule
                    editionMode={editionMode}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  ></EditModule>
                )}
              </>
            )}

            {(module.completed === 0 && null === addmissionDate) && (
              <>
                <div className="barra-superior">
                    <div className="left">
                        <div className="item">
                            <span>Fecha</span>
                            <input type="date" onChange={handleChange} value={acceptedDate} />
                            <span className={errorDate ? "span-error" : "d-none"}>
                                Campo Vacio
                            </span>
                        </div>
                    </div>
                </div>

                {
                  loading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar" disabled={true}>Cargando...</button>
                </div>
                  )
                }
                  {
                    !loading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar">Entrevista agendada</button>
                </div>
                    )
                  }
              </>
            )}

          </div>
        </div>
      </div>

      {/* Modal de avanza candidato */}
      <div
        className="modal fade"
        id="avanza-busqueda-6"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro de avanzar con este candidato?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={handleInterview6}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de no avanza candidato */}
      <div
        className="modal fade"
        id="no-avanza-busqueda-6"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro que deseas rechazar este candidato?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_no_avanza"
                data-dismiss="modal"
                onClick={handleInterview6}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="decidir-busqueda-6"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estás seguro que deseas decidir mas tarde?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_dicidir"
                data-dismiss="modal"
                onClick={handleInterview6}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion6;
